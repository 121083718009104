<template>
  <div
    class="container-custom relative table w-full py-32 lg:py-36 bg-no-repeat bg-center bg-cover"
    :style="{ backgroundImage: `url(${pageTtileData.headerImg})` }"
  >
    <div class="absolute inset-0 bg-black opacity-80"></div>
    <div class="container relative">
      <div class="grid grid-cols-1 text-center mt-10">
        <h3
          class="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white"
        >
          {{ $t(`serverData.${pageTtileData.headerText}`) }}
        </h3>
      </div>
    </div>
  </div>
  <div class="relative">
    <div class="shape overflow-hidden z-1 text-white dark:text-slate-900">
      <svg
        viewBox="0 0 2880 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 150H1437.5H2880V0H2160C1442.5 150 720 0 720 0H0V150Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script setup>
import setPageTitleData from "@/util/pure/setPageTitleData";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const pageTtileData = ref(null);
watch(
  route,
  () => {
    pageTtileData.value = setPageTitleData(route.path); // url에 따라 각기 다른 랜더링 데이터 리턴
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped></style>
