import { Accessibility } from "accessibility";
import { i18n } from "../i18n/i18n";

export default function setAccessibilityTool() {
  const options = {
    labels: {
      increaseText: i18n.global.t("tool.increaseText"),
      decreaseText: i18n.global.t("tool.decreaseText"),
      resetTitle: i18n.global.t("tool.resetTitle"),
      closeTitle: i18n.global.t("tool.closeTitle"),
      menuTitle: i18n.global.t("tool.menuTitle"),
      increaseTextSpacing: i18n.global.t("tool.increaseTextSpacing"),
      decreaseTextSpacing: i18n.global.t("tool.decreaseTextSpacing"),
      increaseLineHeight: i18n.global.t("tool.increaseLineHeight"), // "Increase Line Height" -> i18n 적용
      decreaseLineHeight: i18n.global.t("tool.decreaseLineHeight"), // "Decrease Line Height" -> i18n 적용
      invertColors: i18n.global.t("tool.invertColors"), // "Invert Colors" -> i18n 적용
      grayHues: i18n.global.t("tool.grayHues"), // "Gray Hues" -> i18n 적용
      underlineLinks: i18n.global.t("tool.underlineLinks"), // "Underline Links" -> i18n 적용
      bigCursor: i18n.global.t("tool.bigCursor"), // "Big Cursor" -> i18n 적용
      readingGuide: i18n.global.t("tool.readingGuide"), // "Reading Guide" -> i18n 적용
      textToSpeech: i18n.global.t("tool.textToSpeech"), // "Text to Speech" -> i18n 적용
      speechToText: i18n.global.t("tool.speechToText"), // "Speech to Text" -> i18n 적용
      disableAnimations: i18n.global.t("tool.disableAnimations"), // "Disable Animations" -> i18n 적용
      hotkeyPrefix: i18n.global.t("tool.hotkeyPrefix"), // "Hotkey:" -> i18n 적용
    },
    hotkeys: {
      enabled: true,
    },
    icon: {
      circular: true,
    },
    language: {
      textToSpeechLang: "en-US",
      speechToTextLang: "en-US",
    },
  };
  return new Accessibility(options);
}
