export default {
  message: {
    hello: "반갑다 세상아",
  },
  nav: {
    travleList: "여행지",
    roomList: "숙소",
    tip: "여행팁",
    introduce: "소개",
  },
  serverData: {
    서울: "서울한국",
    부산: "부산한국",
    서울이에요: "서울이에요",
    부산이에요: "부산이에요",
    여행지: "여행지",
    숙소: "숙소",
    소개: "소개",
  },
  공지사항: "공지사항",
  검색: "검색",
  전체: "전체",
  서울: "서울",
  부산: "부산",
  제주: "제주",
  경기: "경기",
  인천: "인천",
  관광: "관광",
  쇼핑: "쇼핑",
  지체장애: "지체장애",
  영유아: "영유아",
  고령자: "고령자",
  호텔: "호텔",
  펜션: "펜션",
  // combinedTags 관련 추가 항목들
  대전: "대전",
  대구: "대구",
  전주: "전주",
  나주: "나주",
  여주: "여주",
  체험: "체험",
  모텔: "모텔",
  리조트: "리조트",
  게스트하우스: "게스트하우스",
  식당: "식당",
  시각장애: "시각장애",
  청각장애: "청각장애",
  경주: "경주",
  장애인전용주차장: "장애인 전용 주차장",
  출입구경사로: "출입구 경사로",
  휠체어대여: "휠체어 대여",
  초기화: "초기화",
  지역: "지역",
  저장: "저장",
  취소: "취소",
  숙소형태: "숙소형태",
  형태: "형태",
  구분: "구분",
  장애인_화장실: "장애인 화장실",
  장애인용_엘리베이터: "장애인용 엘리베이터",
  장애인_전용_주차장: "장애인 전용 주차장",
  출입구_경사로: "출입구 경사로",
  지하철_접근성: "지하철 접근성",
  버스_접근성: "버스 접근성",
  휠체어_대여: "휠체어 대여",
  유모차_대여: "유모차 대여",
  시각장애인_편의시설: "시각장애인 편의시설",
  청각장애인_편의시설: "청각장애인 편의시설",
  점자블록: "점자블록",
  의자테이블: "의자테이블",
  보조견_동반: "보조견 동반",
  안내요원: "안내요원",
  수유실: "수유실",
  장애인_객실: "장애인 객실",
  주요서비스: "주요서비스",
  welcome: {
    title1: "한국 여행의 새로운 문을 여세요!",
    title2: "장애인을 위한 맞춤형 여행 정보 서비스",
    content1:
      "전 세계적으로 사랑받는 K-pop, K-culture, K-food! 이제 누구나 편리하게 한국을 여행할 수 있도록 도와드립니다.",
    content2:
      "우리 서비스는 장애인을 위한 상세한 여행 정보를 제공하여 여러분의 여행을 보다 안전하고 즐겁게 만들어 드립니다.",
    content3:
      "접근 가능한 관광 명소, 숙박 시설, 교통 정보 등 모든 것을 한 곳에서 만나보세요. 지금 바로 Access Korea에서 새로운 한국 여행을 계획해보세요!",
  },
  introduce: {
    header: {
      title1: "모두가 함께하는 한국 여행에 <Access Korea>가 함께 합니다",
      content1:
        "모두를 위한 한국, Access Korea는 장애가 있는 외국인 관광객들이 한국의 아름다움을 편안하게 경험할 수 있도록 설계된 여행 서비스입니다.",
      content2:
        " “모두가 함께하는 한국 여행”이라는 슬로건 아래, 역사적인 명소부터 현대적 도시까지 한국의 다양한 매력을 무장애 접근성을 갖춘 특별한 여행으로 제공합니다. ",
      content3:
        "전용 차량, 무장애 시설이 완비된 숙박과 관광지, 세심한 맞춤형 서비스를 통해 누구나 자유롭고 즐겁게 한국을 탐험할 수 있도록 지원합니다. ",
      content4:
        "한국의 풍부한 자연과 문화, 역사를 Access Korea와 함께 안전하고 편안하게 경험해 보세요.",
    },
    info1: {
      title: "대한민국 무장애<br/> 여행정보",
      content: "한국 여행에서 무장애여행에 필요한 필수 정보 제공",
    },
    info2: {
      title: "여행지 정보<br/> <br/> ",
      content: "다양한 여행지/체험지 중 무장애여행이 가능한 여행지 소개",
    },
    info3: {
      title: "숙소 정보<br/> <br/> ",
      content: "지역별 이용 가능한 숙박시설 소개",
    },
    info4: {
      title: "가이드서비스 <br/>(추후예정)",
      content: "외국인 여행객의 원활한 여행을 위한 투어가이드 서비스 제공",
    },
    table: {
      header: {
        번호: "번호",
        제목: "제목",
        작성일: "작성일",
        작성자: "작성자",
      },
      tableData: {
        line1: {
          title: "한국 여행을 위한 여행팁이 업데이트 되었습니다",
          content1:
            "K-WHEEL TOUR는 여러분들의 편안한 여행을 위해 한국여행에 필요한 다양한 정보를 제공하고 있습니다. ",
          content2:
            "[여행팁]은 한국에 입국하는 것부터 대중교통, 화장실, 인터넷 이용 등 가장 기본이 되는 한국 여행팁을 정리하였습니다. 한국 여행에 많은 도움이 되길 바라며, 앞으로도 꾸준히 업데이트 할 수 있도록 노력하겠습니다.",
        },
        line2: {
          title: "Access Korea 베타 서비스를 시작합니다.",
          content1:
            "안녕하세요. Access Korea입니다.<br/>저희 Access Korea는 장애인 및 이동이 불편한 모든 분들이 좀더 편안하게 한국 여행을 할 수 있도록 다양한 정보를 제공하기 위해 시작되었습니다.<br/>앞으로 보다 다양하고 편리한 여행정보를 제공할 수 있도록 노력하겠습니다.",
          content2:
            "Access Korea는 여러분들의 편안한 여행을 위해 한국여행에 필요한 다양한 정보를 제공하고 있습니다.<br/>[여행팁]은 한국에 입국하는 것부터 대중교통, 화장실, 인터넷 이용 등 가장 기본이 되는 한국 여행팁을 정리하였습니다.<br/>한국 여행에 많은 도움이 되길 바라며, 앞으로도 꾸준히 업데이트 할 수 있도록 노력하겠습니다.",
        },
      },
    },
  },
  tip: {
    title: "한국 무장애 여행을 위한 가이드",
    airport: {
      title: "공항",
      content1:
        "한국에는 여러 공항이 있지만, 대부분은 인천국제공항을 통해 입국하게 됩니다. 인천국제공항은 서울에서 1시간정도 거리에 있으며, 규모가 매우 큰 공항으로 다양한 편의시설을 갖추고 있습니다. 김포국제공항은 한국과 가까운 거리(중국/일본/대만) 또는 국내선을 위주로 운항하는 공항으로 서울과 가장 가까운 공항입니다.",
      content2:
        "인천국제공항은 장애인 화장실, 무빙워크 등 다양한 장애인편의시설이 마련되어 있으며, ‘장애인안심여행센터’를 운영하고 있어 여러 도움을 받을 수 있습니다. 또한, 인천국제공항에서 서울로 오려면 지하철이나 버스, 택시 등을 다양한 교통 수단을 이용할 수 있습니다.",
      content3:
        "김포국제공항 역시 최근 리모델링을 통해 다양한 장애인편의시설을 마련하였으며, 서울에서 가장 가까운 만큼 지하철, 버스, 택시 등을 활용해서 서울로 이동할 수 있습니다.",
      content4:
        "김해국제공항은 부산과 가장 가까운 공항으로 중국/일본/동남아시아 등지의 노선이 연결된 공항으로 지하철, 버스, 택시 등을 이용해 부산으로 이동이 가능합니다.",
    },
    about: {
      title: "한국 여행에 대해",
      content1:
        "한국은 5천년의 역사를 가진 나라지만, 1945년 큰 전쟁을 겪으면서 많은 것들이 파괴되었습니다. 그 위에 새로운 도시가 생겨나고 새로운 문화가 만들어지면서, 오랜 역사와 현대적 건물이 어우러진 다이나믹한 지금의 한국을 형성했습니다",
      content2:
        "한국은 3면이 바다로 이루어져 있어 다양한 해산물이 있으며, 산이 많은 지형으로 도시 어디에서나 푸른 산을 만날 수 있습니다. 기후는 4계절이 뚜렸하여 방문 시기에 따라 다양한 매력을 느낄 수 있습니다.",
      content3:
        "한국의 공식 화폐는 “원”입니다. 원화는 오만원권, 만원권, 5천원권, 1천원권 지폐와 오백원, 백원, 오십원, 십원 동전으로 되어있습니다. 대부분의 상점 등에선 신용카드를 사용하므로 화폐보다 편리하게 이용할 수 있습니다.",
      content4:
        "한국의 마트, 편의점은 도심 곳곳에 위치하고 있고, 24시간 영업하는 곳이 많아 편리하게 이용할 수 있습니다. 또한, 치안이 안전하여 늦은 밤에도 안전하게 여행 할 수 있습니다. 늦은 밤 한강의 야경을 즐겨보세요.",
    },
    train: {
      title: "기차",
      content1:
        "한국은 철도가 매우 잘 발달한 나라이며, 일반 기차와 초고속열차(이하 KTX)로 나뉘어집니다. 현재는 일반열차는 점점 줄어들고 있고, 초고속열차로 대부분 대체되고 있습니다. 초고속 열차는 KTX와 SRT로 나뉘는데, 운영사에 차이가 있을뿐 역사 이용에 대한 규정 등은 크게 차이가 없습니다. 다만, 예매시 KTX와 SRT는 개별 서비스를 이용해야 합니다.",
      content2:
        "KTX를 이용하면 서울에서 부산까지 2~3시간이면 이동이 가능하며, 휠체어를 이용한 이동역시 편리하게 이용할 수 있습니다. 대부분의 기차역에서는 휠체어 대여도 가능합니다. 한국은 도로가 잘 발달되어 있긴하지만 교통이 복잡하고 교통체증이 심해 대도시 중심으로 이동할때는 기차를 이용하는 것이 매우 편리합니다.",
      content3:
        "KTX에는 보통 수동휠체어석과 전동휠체어석이 2~5석 마련되어 있습니다.(전동차량에 따라 상이할 수 있음) 또한, 휠체어 좌석 옆에 장애인화장실이 가까이에 있어 쉽게 이용할 수 있습니다. 탑승 시에는 승강장 사이에 휠체어가 빠지지 않도록 발판이 있어 승무원의 도움을 받아 보다 안전하게 탑승하시기 바랍니다.",
      content4:
        "다만, 예매시에 휠체어좌석을 별도 구매해야하며, 출발 30분전에는 수동휠체어 좌석은 일반좌석으로 전환돼 비장인도 구매가 가능하니, 미리 표를 구매하는게 중요합니다. 휠체어 전용석은 공간이 넓어 입석 승객이나 캐리어같은 큰 짐이 점유하고 있는 경우가 있으니 이럴 경우 승무원의 도움을 받으시길 바랍니다.",
    },
    publicTrans: {
      title: "대중교통",
      content1:
        "택시: 한국은 택시가 매우 잘 발달되어 있으며, 편리하고 빠르게 원하는 목적지로 이동하기에 편리합니다. 택시는 보통 택시 승강장이나 지나가는 택시를 잡을수 있습니다. 최근에는 택시 앱이 잘 발달되어 앱을 이용하는게 매우 편리합니다.(카카오택시) 다만, 일반 택시의 경우 휠체어 이용은 거의 불가능합니다.",
      content2:
        "휠체어 전용택시: 휠체어 전용택시는 지방자치단체에서 제공하거나 사설서비스로 제공하는 방식이 있습니다. 지자체에서 제공하는 장애인콜택시는 국가등록 장애인이나 외국인 여행객도 이용가능합니다.(외국인 장애인 이용은 지자체별 상이할 수 있으니 이용전에 문의 필수) 다만, 지자체에서 제공하는 장애인콜택시는 이용자가 많을 경우 대기가 길게 발생할 수 있어 지정된 시간에 이용이 어려울 수 있으며, 지역 간 이동은 제한이 있을 수 있습니다. 사설서비스의 경우 지자체 장애인콜택시 보다 좀더 자유롭게 예약이 가능하며, 파파, 헤이드 등의 서비스가 있습니다. 이용은 각각의 모바일앱을 통해 예약할 수 있으습니다. 다만 지자체 장애인콜택시에 요금이 비싼 단점이 있습니다.",
      content3:
        "버스: 한국은 버스 노선이 잘 발달되어 버스를 통한 이동이 매우 편리하며, 요금 또한 저렴한 편입니다. 다만, 한국의 버스는 일부 저상버스로 휠체어 탑승이 가능한 버스도 있지만 도로교통 상 휠체어를 이용한 버스 이용은 사실상 어렵다고 생각하시는게 좋습니다. 버스 이용은 혼자 이동이 가능한 또는 동승자가 있는 시각/청각장애인이 이용하기에 적합합니다. 유모차나 거동이 불편한 노인도 버스 이용은 불편함이 있습니다.",
      content4:
        "지하철: 지하철은 특정 도시에 잘 발달되어 있습니다. 특히, 서울-인천-경기도(수도권) 주변은 지하철을 이용한 이동이 매우 편리하며, 공항까지도 연결되어 있습니다. 그 외 대전, 대구, 부산 등 대도시에 지하철이 있으나 노선이 많지는 않아 수도권을 제외한 지역에선 유용하진 않습니다. 수도권의 경우 앞서 설명한것 처럼 매우 잘 발달되있으며, 시설이 깨끗하고 쾌적합니다. 대부분의 지하철역에는 장애인 화장실이 잘 설치되어 있습니다. 다만, 엘리베이터는 역마다 설치안된곳이 많아 역사에 접근하는게 불편할 수 있습니다. 엘리베이터가 없는 경우 역무원을 불러 휠체어 리프트를 이용할 수 있습니다. 휠체어 리프트는 대부분의 역에 설치되어 있으나 이용에 위험성이 있으나 역무원의 안내를 받아 이용하시길 바랍니다. 지하철에도 대부분 휠체어 전용석이 마련되어 있으니 참고하여 탑승하시면 됩니다.",
      content5:
        "기타 이동수단: 서울시는 다누림관광 서비스를 제공하고 있습니다. 다누림관광은 무장애관광서비스로 휠체어전용 버스나 밴 등을 제공하는 등 교통 및 관광지 소개 등의 서비스를 제공하고 있습니다. 그 외 공항픽업, 시티투어, 여행자 지원 등의 서비스가 있으니 미리 확인하고 필요한 서비스를 이용하시기 바립니다.(다누리관광) 그 외에도 지역별 지자체에서 제공하는 무장애여행 서비스가 있으니 방문하려는 지자체의 홈페이지를 통해 확인하시기 바랍니다.",
    },
    toilet: {
      title: "화장실",
      content1:
        "한국의 화장실은 공공기관, 지하철역, 주요관광지 등의 시설에 무료로 개방되어 있어 쉽게 이용가능합니다. 또한, 대형 건물의 경우 1층 화장실을 개방하도록 되어 있습니다. 이렇게 공개된 화장실은 대부분 시설이 깨끗하고 장애인 화장실이 별도로 마련된 곳이 대부분입니다. 다만, 오래된 건물이나 작은 건물은 화장실이 개방되어 있어도 장애인 화장실이 별도로 없을 가능성이 높으니 이용에 참고하시기 바랍니다.",
      content2:
        "특히, 지방 소도시의 경우 오래된 건물이 많아 장애인전용화장실이 없는 경우가 많이 있습니다. 이경우 공공기관 화장실을 이용하시기 바랍니다. 다만, 공공기관 화장실은 오후 6시 이후에는 개방되지 않을 수 있으니 참고하시기 바랍니다.",
      content3:
        "비교적 최근에 지어진 건물의 화장실에는 장애인화장실은 물론 기저귀 교환대 등이 잘 설치되어 있습니다.",
    },
    drug: {
      title: "의약품 반입",
      content1:
        "여행 시 평소 복용하던 약이 있다면 복용량 등에 따라 일정 기준만큼 반입이 가능합니다. 의약품 및 건강기능식품은 본인 사용 기준 6병 또는 용법상 3개월 복용량 이내로 통관이 가능합니다. 다만, 오남용우려가 있는 의약품(발기부전치료용 알프로스타딜 함유제제 등)은 국내의사가 발행한 처방전에 정해진 수량만 가능합니다.",
      content2:
        " 그 외 CITES 규제물품 성분 함유 물품이나 식약처의 수입불허 또는 유해통보를 받은 품목이나 해당 성분이 포함된 경우에는 반입이 불가하며, 외포장상 성분표시가 불명확한 물품도 제한됩니다. 또한, 수면제나 신경안정제로 복용하는 졸피뎀, 디아제팜, 알프라졸람, 로라제팜 등 마약류가 포함된 의약품을 반입할 경우에는 식품의약품안전처장의 승인을 받아야 반입이 가능합니다. 해당 의약품을 반입하려는 경우 사전에 식품의약품안전처에 문의하시기 바랍니다.(식품의약품안전처 마약정책과 ☎043-719-2810)",
    },
    discount: {
      title: "장애인 할인",
      content1:
        "한국은 대중교통, 관광지 입장, 공영주차장 이용 등에 다양한 장애인 할인 혜택이 제공됩니다. 이러한 혜택을 받기 위해서는 대부분 한국정부에서 인정한 장애인복지카드나 증명을 필요로 하나, 여행객이 이러한 증명을 받는건 매우 어려운 일입니다.",
      content2:
        "다만, 지자체 또는 관광지, 공공시설물 등에 따라 해당 증명이 없어도 현장에서 장애인 할인 혜택을 제공하는 경우가 있으니 방문 전 또는 입장 전에 꼭 문의하시기 바랍니다.",
    },
    help: {
      title: "도움이 필요할 때",
      content1:
        "비상상황이 발생하면 긴급전화를 통해 도움을 받을 수 있습니다. 상황별 긴급 번호는 경찰서(112), 소방서 및 응급의료(119), 감영성질환(1339)입니다. 그 외 여행상담 긴급전화 1330, 분실물 등 182로 연락하면 도움을 받을 수 있습니다.",
      content2:
        "이미지를 다운받아 스마트폰에 보관하고, 비상상황 시 활용하시기 바랍니다. ",
    },
    airplane: {
      title: "항공기 이용안내",
      content1:
        "휠체어 이용: 기내에서 휠체어 이용 시 사전 예약을 통해 기내 전용 휠체어 이용이 가능합니다.(개인용 이용 시 기내 사이즈(39㎝ × 세로 69㎝ × 높이 84㎝)에 적합한 경우에만 이용 가능) 기내용 휠체어 이용 시 개인 휠체어는 무료 운송이 가능합니다.",
      content2:
        "전동휠체어 이용: 전동 휠체어는 탑승 수속 시 위탁하여야 하며, 보통의 경우 탑승구까지 항공사에서 제공한 휠체어를 이용합니다. 전동 휠체어는 무료로 운송되며, 배터리가 분리되지 않는 경우 전류가 흐르지 않게 조치한 후 운송됩니다. 배터리가 분리되는 경우에는 분리된 배터리를 휴대해야하며, 분리된 배터리는 300Wh를 초과하지 않아야 합니다. 2개의 배터리로 구동되는 경우 각각의 합이 300Wh를 초과하지 않아야 합니다. 전동 휠체어는 사이즈 및 배터리 타입에 따라 운송 가능 여부 및 처리 절차가 달라질 수 있으니 자세한 사항은 각 항공사의 서비스센터로 문의해 주세요.",
      content3:
        "보조호흡장비 이용: 보조호흡장비를 사용하는 승객은 출발 48시간 전까지 각 항공사의 서비스 센터로 연락해야 합니다. 안전을 위해 기내 전원을 사용할 수 없습니다. 건식/160Wh이하 리튬 배터리 타입 장비를 직접 소지해야 합니다. 휴대용산소발생기(POC)를 사용하는 승객은 예약접수 시, 의사소견서를 제출해야 하며, 미국 연방항공청(FAA : Federal Aviation Administration) 또는 해당 국가의 요구조건을 충족하는 테스트 결과 입증 라벨이 부착되어 있어야 합니다. 의사소견서 양식은 각 항공사 홈페이지에서 다운로드 할 수 있습니다.",
    },
  },
  tool: {
    increaseText: "텍스트 크기 증가",
    decreaseText: "텍스트 크기 감소",
    resetTitle: "초기화",
    closeTitle: "닫기",
    menuTitle: "웹 접근성 도구",
    increaseTextSpacing: "텍스트 간격 늘리기",
    decreaseTextSpacing: "텍스트 간격 줄이기",
    increaseLineHeight: "줄 간격 늘리기",
    decreaseLineHeight: "줄 간격 줄이기",
    invertColors: "색상 반전",
    grayHues: "회색조",
    underlineLinks: "링크 밑줄 표시",
    bigCursor: "큰 커서",
    readingGuide: "읽기 가이드",
    textToSpeech: "텍스트를 음성으로",
    speechToText: "음성을 텍스트로",
    disableAnimations: "애니메이션 비활성화",
    hotkeyPrefix: "단축키:",
  },
};
