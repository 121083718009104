import {
  createRouter,
  createWebHashHistory,
  // createWebHashHistory,
  createWebHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/pages/main/index.vue"),
    meta: {
      title: "Access Korea",
    },
  },
  {
    path: "/tip",
    name: "tip",
    component: () => import("@/pages/tip/index.vue"),
    meta: {
      title: "Access Korea - 여행팁",
    },
  },
  {
    path: "/roomList",
    name: "roomList",
    component: () => import("@/pages/roomList/index.vue"),
    meta: {
      title: "Access Korea - 숙소",
    },
  },
  {
    path: "/roomList/:id",
    name: "roomListDetail",
    component: () => import("@/pages/roomList/[id]/index.vue"),
    meta: {
      title: "Access Korea - 숙소",
    },
  },
  {
    path: "/travelList/:id",
    name: "travelListDetail",
    component: () => import("@/pages/travelList/[id]/index.vue"),
    meta: {
      title: "Access Korea - 숙소",
    },
  },
  {
    path: "/travelList",
    name: "travelList",
    component: () => import("@/pages/travelList/index.vue"),
    meta: {
      title: "Access Korea - 여행지",
    },
  },
  {
    path: "/introduce",
    name: "introduce",
    component: () => import("@/pages/introduce/index.vue"),
    meta: {
      title: "Access Korea - 소개",
    },
  },
  {
    path: "/:404(.*)",
    name: "404",
    component: () => import("@/pages/main/index.vue"),
  },
  {
    path: "/:catchAll(.*)", // 모든 잘못된 경로를 잡아냄
    redirect: "/", // 메인 페이지로 리디렉션
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
