<template>
  <div class="flex justify-center py-16">
    <span class="spinner"></span>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.spinner {
  width: 5rem;
  height: 5rem;
  border: 1rem solid rgba(0, 0, 0, 0.1); /* 회색 테두리 */
  border-top: 1rem solid #34db50; /* 상단 테두리 색상 (파란색) */
  border-radius: 50%; /* 원형으로 만듦 */
  animation: spin 1s linear infinite; /* 회전 애니메이션 */
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* 시작 시 0도 회전 */
  }
  100% {
    transform: rotate(360deg); /* 끝에서 360도 회전 */
  }
}
</style>
