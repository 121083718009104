<template>
  <Navbar :container="'container'" :navLight="'justify-end'" />
  <MainHeader />
  <Layout>
    <router-view></router-view>
  </Layout>
  <Footer />
</template>

<script setup>
import { onMounted, watch } from "vue";
import Footer from "./components/footers/footer.vue";
import Navbar from "./components/navbar/navbar.vue";
import setAccessibilityTool from "./util/pure/accessbilityTool";
import Layout from "./components/layout/Layout.vue";
import MainHeader from "./pages/main/components/MainHeader.vue";
import { useRoute } from "vue-router";
import { scrollToPosition } from "./util/pure/scrollToPosition";
import { i18n } from "./util/i18n/i18n";

const route = useRoute();

let accessibilityTool;

const initializeAccessibilityTool = () => {
  if (accessibilityTool) {
    accessibilityTool.destroy(); // 기존 접근성 도구가 있으면 제거
  }
  accessibilityTool = setAccessibilityTool(); // 새롭게 접근성 도구 초기화
  return accessibilityTool;
};

// 언어가 변경될 때마다 접근성 도구 재설정
watch(
  () => i18n.global.locale,
  () => {
    initializeAccessibilityTool();
  },
  { immediate: true }
);

watch(route, () => {
  scrollToPosition(0);
});
</script>

<style>
/* 로딩 스크린 스타일 */

/* accessibilityTool 540미만 뷰포트에선 안보이게 */
@media (max-width: 539px) {
  ._access-icon {
    display: none !important;
  }
}
</style>
