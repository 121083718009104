<template>
  <Suspense>
    <template #default>
      <div>
        <Welcome v-if="isRootRoute" />
        <PageTitle v-else />
      </div>
    </template>
    <template #fallback>
      <div class="loading-screen">
        <h1 class="loading-text">
          <Spinner />
        </h1>
      </div>
    </template>
  </Suspense>
</template>

<script setup>
import PageTitle from "@/components/pageTitle/PageTitle.vue";
import Spinner from "@/components/spinner/Spinner.vue";
import { useRouteStore } from "@/util/store/useRouteStore";
import { computed, watch } from "vue";
import { defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";

const route = useRoute(); // 현재 경로 정보를 가져옴
const routeStore = useRouteStore();
// 루트 경로 확인
const isRootRoute = computed(() => route.path === "/");

const Welcome = defineAsyncComponent(() =>
  import("@/components/pageTitle/Welcome.vue")
);
watch(
  route,
  (newRoute) => {
    routeStore.setRouteInfo(newRoute.path);
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: gray;
  background-size: cover;
  position: relative;
}

.loading-text {
  font-size: 4rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
</style>
