import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/scss/tailwind.scss";
import router from "./router";
import { i18n } from "./util/i18n/i18n";
import store from "./util/store";
import { createPinia } from "pinia";
import { VueQueryPlugin } from "@tanstack/vue-query";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import vueSlideUpDown from "vue-slide-up-down";

const app = createApp(App);
const pinia = createPinia();
router.beforeEach((to, from, next) => {
  const defaultTitle = "My App"; // 기본 타이틀 설정
  document.title = to.meta.title || defaultTitle;
  next();
});

app.use(router);
app.use(store);
app.use(i18n);
app.use(pinia);
app.component("slide-up-down", vueSlideUpDown);
app.use(VueQueryPlugin);
app.use(Vue3Toastify, {
  autoClose: 3000,
});
app.mount("#app");
