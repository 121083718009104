import { defineStore } from "pinia";

export const useRouteStore = defineStore("route", {
  state: () => ({
    routeInfo: {
      routeUrl: "",
      headerText: "",
      headerImg: "",
    },
  }),
  actions: {
    setRouteInfo(url) {
      switch (url) {
        case "/tip":
          this.routeInfo.headerText = "여행팁";
          this.routeInfo.headerImg = require("../../assets/images/pageTitle/pageTitle.webp");
          this.routeUrl = url;
          break;
        case "/introduce":
          this.routeInfo.headerText = "소개";
          this.routeInfo.headerImg = require("../../assets/images/pageTitle/pageTitle.webp");
          this.routeUrl = url;
          break;
        case "/roomList":
          this.routeInfo.headerText = "숙소";
          this.routeInfo.headerImg = require("../../assets/images/pageTitle/pageTitle.webp");
          this.routeUrl = url;
          break;
        case "/travelList":
          this.routeInfo.headerText = "여행지";
          this.routeInfo.headerImg = require("../../assets/images/pageTitle/pageTitle.webp");
          this.routeUrl = url;
          break;
      }
    },
  },
});
