export default function setPageTitleData(url) {
  switch (true) {
    case url.includes("tip"):
      return {
        headerText: "여행팁",
        headerImg: require("../../assets/images/pageTitle/pageTitle.webp"),
        route: url,
      };
    case url.includes("introduce"):
      return {
        headerText: "회사소개",
        headerImg: require("../../assets/images/pageTitle/pageTitle.webp"),
        route: url,
      };
    case url.includes("roomList"):
      return {
        headerText: "숙소",
        headerImg: require("../../assets/images/pageTitle/pageTitle.webp"),
        route: url,
      };
    case url.includes("travelList"):
      return {
        headerText: "여행지",
        headerImg: require("../../assets/images/pageTitle/pageTitle.webp"),
        route: url,
      };
  }
}
