export default {
  message: {
    hello: "hello world",
  },
  nav: {
    travleList: "Destinations",
    roomList: "Accommodations",
    tip: "Travel Tips",
    introduce: "About Us",
  },
  serverData: {
    서울: "Seoul",
    부산: "Busan",
    서울이에요: "This is Seoul",
    부산이에요: "This is Busan",
    여행지: "Destinations",
    숙소: "Accommodations",
    여행팁: "Travel Tips",
    회사소개: "About Us",
  },
  공지사항: "Notice board",
  여행지: "여행지",
  전체: "All",
  서울: "Seoul",
  저장: "Save",
  취소: "Cancle",
  부산: "Busan",
  제주: "Jeju",
  경기: "Gyeoung-Gi",
  인천: "In-Cheon",
  관광: "Travel",
  쇼핑: "Shopping",
  리조트: "Resort",
  지체장애: "Physical disability",
  영유아: "Infants",
  고령자: "Elderly",
  호텔: "Hotel",
  게스트하우스: "Guest House",
  모텔: "Motel",
  펜션: "Korean rental cottage",
  대전: "Daejeon",
  대구: "Daegu",
  전주: "Jeonju",
  나주: "Naju",
  여주: "Yeoju",
  체험: "Experience",
  식당: "Restaurant",
  시각장애: "Visual Impairment",
  청각장애: "Hearing Impairment",
  경주: "Gyeongju",
  장애인전용주차장: "Disabled parking",
  출입구경사로: "Entrance ramp",
  휠체어대여: "Wheelchair rental",
  초기화: "Reset",
  검색: "Search Result",
  지역: "City",
  형태: "Type",
  구분: "Disablity Type",
  장애인_화장실: "Accessible Restroom",
  장애인용_엘리베이터: "Accessible Elevator",
  장애인_전용_주차장: "Accessible Parking",
  출입구_경사로: "Entrance Ramp",
  지하철_접근성: "Subway Accessibility",
  버스_접근성: "Bus Accessibility",
  휠체어_대여: "Wheelchair Rental",
  숙소형태: "Room Type",
  유모차_대여: "Stroller Rental",
  시각장애인_편의시설: "Facilities for the Visually Impaired",
  청각장애인_편의시설: "Facilities for the Hearing Impaired",
  수유실: "Nursing Room",
  장애인_객실: "Accessible Room",
  주요서비스: "Main Services",
  점자블록: "Braille Block",
  의자테이블: "Chair and Table",
  보조견_동반: "Guide Dog Accompanied",
  안내요원: "Support Staff",
  welcome: {
    title1: "Open a New Door to Traveling in Korea!",
    title2:
      "Customized Travel Information Service for People with Disabilities",
    content1:
      "Beloved worldwide for K-pop, K-culture, and K-food! We help everyone travel to Korea conveniently.",
    content2:
      "Our service provides detailed travel information for people with disabilities to make your journey safer and more enjoyable.",
    content3:
      "Find everything in one place, from accessible tourist attractions and accommodation to transportation information. Start planning your new journey in Korea right now at Access Korea!",
  },
  introduce: {
    smallHeader: {
      title: "Main Service",
    },
    header: {
      title1: "<Access Korea> is with you on your trip to Korea together",
      title2: "Access Korea is With You",
      content1:
        "Korea for All, 'Access Korea' is a travel service designed to allow foreign tourists with disabilities to comfortably experience the beauty of Korea. ",
      content2:
        'Under the slogan of "Traveling Korea Together", we provide a special tour with barrier-free access to Korea\'s various attractions, from historical sites to modern cities. ',
      content3:
        "We support anyone to freely and enjoyably explore Korea through private vehicles, fully equipped accommodations and tourist attractions, and meticulous customized services. ",
      content4:
        "Experience Korea's rich nature, culture, and history safely and comfortably with 'Access Korea'.",
    },
    info1: {
      title: "Barrier-Free Travel Information in Korea",
      content:
        "Providing essential information for barrier-free travel in Korea.",
    },
    info2: {
      title: "Tourist Attraction Information",
      content:
        "Introducing tourist destinations that offer barrier-free travel experiences.",
    },
    info3: {
      title: "Accommodation Information",
      content: "Introducing available accommodations by region.",
    },
    info4: {
      title: "Guide Services (Coming Soon)",
      content:
        "Providing tour guide services for smooth travel experiences for foreign tourists.",
    },
    table: {
      header: {
        번호: "Number",
        제목: "Title",
        작성일: "Date",
        작성자: "Author",
      },
      tableData: {
        line1: {
          title: "Travel tips for traveling in Korea have been updated",
          content1:
            "K-WHEEL TOUR provides a variety of information needed for traveling in Korea to ensure your comfortable journey.",
          content2:
            "[Travel Tips] covers the most basic tips for traveling in Korea, from entering Korea to using public transportation, restrooms, and the internet. We hope this will be helpful for your trip to Korea, and we will continue to work hard to provide ongoing updates.",
        },
        line2: {
          title: "Access Korea launches beta service.",
          content1:
            "Hello, this is Access Korea.<br/>Access Korea was created to provide various information to help people with disabilities or mobility difficulties travel more comfortably in Korea.<br/>We will continue to strive to provide more diverse and convenient travel information.",
          content2:
            "Access Korea provides a variety of information needed for traveling in Korea to ensure your comfortable journey.<br/>[Travel Tips] covers the most basic tips for traveling in Korea, from entering Korea to using public transportation, restrooms, and the internet.<br/>We hope this will be helpful for your trip to Korea, and we will continue to work hard to provide ongoing updates.",
        },
      },
    },
  },
  tip: {
    title: "Guide for Accessible Travel in Korea",
    airport: {
      title: "Airport",
      content1:
        "There are several airports in Korea, but most people enter the country through Incheon International Airport. Incheon International Airport is about an hour away from Seoul and is a very large airport with a variety of facilities. Gimpo International Airport is closer to Korea (serving routes mainly to China, Japan, and Taiwan) and domestic flights, making it the closest airport to Seoul.",
      content2:
        "Incheon International Airport is equipped with various facilities for people with disabilities, including accessible restrooms and moving walkways. It also operates the Disability Assistance Travel Center, where you can receive various types of assistance. Additionally, you can use various transportation options such as the subway, buses, or taxis to travel from Incheon International Airport to Seoul.",
      content3:
        "Gimpo International Airport has also recently undergone renovations to include various facilities for people with disabilities. As it is the closest airport to Seoul, you can easily travel to Seoul using transportation options such as the subway, buses, or taxis.",
      content4:
        "Gimhae International Airport is the closest airport to Busan and connects to routes in China, Japan, Southeast Asia, and other regions. You can easily travel to Busan using transportation options such as the subway, buses, or taxis.",
    },
    about: {
      title: "About korea travel",
      content1:
        "Korea has a history of 5,000 years, but much of it was destroyed during a major war in 1945. On top of this, new cities and cultures emerged, creating a dynamic Korea where ancient history and modern buildings coexist.",
      content2:
        "Korea is surrounded by the sea on three sides, offering a variety of seafood, and its mountainous terrain allows you to encounter green mountains anywhere in the cities. The climate is characterized by four distinct seasons, offering different attractions depending on the time of visit.",
      content3:
        'The official currency of Korea is the "won". The won comes in banknotes of 50,000, 10,000, 5,000, and 1,000 won, and coins of 500, 100, 50, and 10 won. Most shops accept credit cards, making transactions more convenient than using cash.',
      content4:
        "Korea's supermarkets and convenience stores are located throughout urban areas and many are open 24 hours, making them very convenient. Additionally, the safety of the area allows for secure travel even late at night. Enjoy the night view of the Han River late at night.",
    },
    train: {
      title: "Train",
      content1:
        "Korea has a highly developed railway system, divided into regular trains and high-speed trains (KTX). Currently, regular trains are gradually decreasing, with most being replaced by high-speed trains. High-speed trains are divided into KTX and SRT, which differ only in their operating companies; there are no significant differences in terms of station usage regulations. However, when booking tickets, KTX and SRT require separate reservations.",
      content2:
        "By using the KTX, it is possible to travel from Seoul to Busan in 2 to 3 hours, and it is also convenient for wheelchair users. Most train stations offer wheelchair rental services. While Korea has well-developed roads, traffic congestion is severe, especially in large cities, so using the train for travel in and around major urban centers is highly convenient.",
      content3:
        "The KTX typically offers 2 to 5 seats designated for manual and electric wheelchairs (the number may vary depending on the train model). Additionally, an accessible restroom is located close to the wheelchair seats for convenient use. When boarding, there is a ramp to prevent wheelchairs from slipping between the platform and the train. It is recommended to seek assistance from the staff to board safely.",
      content4:
        "However, when booking, you must purchase wheelchair seats separately. It’s important to note that manual wheelchair seats may be converted to regular seats 30 minutes before departure, making them available for non-disabled passengers, so it's crucial to purchase your ticket in advance. The wheelchair-designated seats offer more space, and sometimes standing passengers or large luggage such as suitcases may occupy this space. In such cases, it is advisable to seek assistance from the train staff.",
    },
    publicTrans: {
      title: "Public Transport",
      content1:
        "Taxi: Taxis in Korea are highly developed, making it convenient and fast to reach your destination. Taxis can usually be hailed at taxi stands or by flagging one down on the street. Recently, taxi apps have become widely used, making it very convenient to use apps like KakaoTaxi. However, regular taxis are generally not accessible for wheelchair users.",
      content2:
        "Wheelchair-accessible taxis: Wheelchair-accessible taxis are provided either by local governments or through private services. Disability call taxis provided by local governments can be used by both registered disabled individuals and foreign tourists with disabilities. (However, usage for foreign disabled tourists may vary depending on the local government, so it's necessary to inquire in advance.) That said, the demand for disability call taxis provided by local governments is high, which can result in long wait times, making it difficult to use them at a specific time, and there may be restrictions on traveling between regions. Private services such as Papa and Heyde allow more flexible reservations compared to local government disability call taxis, and they can be reserved via their respective mobile apps. However, they are more expensive than local government disability call taxis.",
      content3:
        "Bus: Korea has a well-developed bus network, making buses a convenient and affordable mode of transportation. However, while some buses are low-floor buses that can accommodate wheelchairs, it is generally difficult to use buses with a wheelchair due to traffic conditions. Buses are more suitable for visually or hearing-impaired individuals who can travel alone or with a companion. Strollers or elderly individuals with mobility issues may also find it inconvenient to use buses.",
      content4:
        "Subway: Subways are well-developed in certain cities, particularly in the Seoul-Incheon-Gyeonggi (capital region) area, where the subway system is very convenient and even connects to the airport. While other cities like Daejeon, Daegu, and Busan also have subways, the number of lines is limited, so outside of the capital region, the subway may not be as useful. In the capital region, the subway is highly developed, with clean and comfortable facilities. Most subway stations have well-equipped disabled toilets. However, not all stations have elevators, which can make accessing the subway difficult. If there is no elevator, a station attendant can be called to use a wheelchair lift. Most stations are equipped with wheelchair lifts, but they come with some risks, so it’s recommended to use them with the guidance of a station attendant. Most subway cars also have designated wheelchair seats.",
      content5:
        'Other transportation options: Seoul provides the "Danurim" tourism service. Danurim Tourism offers barrier-free tourism services, such as wheelchair-accessible buses or vans, as well as support for transportation and tourism destinations. Additionally, airport pickups, city tours, and traveler assistance services are available, so it\'s recommended to check in advance and use the necessary services (Danurim Tourism). Other local governments also offer barrier-free travel services, so check the websites of the local governments you plan to visit for more information.',
    },
    toilet: {
      title: "Toilet",
      content1:
        "Public restrooms in Korea are freely accessible at public institutions, subway stations, and major tourist attractions, making them easy to use. In large buildings, the restrooms on the first floor are usually open to the public. These open restrooms are generally clean, and most of them have separate facilities for people with disabilities. However, in older or smaller buildings, even if restrooms are available, they may not have separate facilities for people with disabilities, so please keep this in mind.",
      content2:
        "In particular, in small towns in rural areas, many buildings are older and do not have dedicated restrooms for people with disabilities. In such cases, it is recommended to use the restrooms in public institutions. However, please note that restrooms in public institutions may not be open after 6 PM.",
      content3:
        "Restrooms in relatively newly constructed buildings are well-equipped, not only with facilities for people with disabilities but also with baby changing stations.",
    },
    drug: {
      title: "Bringing in medication",
      content1:
        "If you have medications that you normally take, you are allowed to bring them with you according to specific guidelines based on the dosage. Medications and health supplements can be cleared through customs if they are for personal use, limited to six bottles or a three-month supply based on the prescribed dosage. However, for medications with potential for misuse (such as those containing alprostadil for treating erectile dysfunction), only the quantity specified in a prescription issued by a domestic doctor is permitted.",
      content2:
        "In addition, items containing ingredients regulated under CITES (Convention on International Trade in Endangered Species), items prohibited for import by the Ministry of Food and Drug Safety, or items that have been deemed harmful, are not allowed. Items with unclear ingredient labels on the outer packaging are also restricted. Furthermore, if you plan to bring in medications containing narcotics such as zolpidem, diazepam, alprazolam, lorazepam (which are used as sleeping pills or tranquilizers), you must obtain approval from the Ministry of Food and Drug Safety. If you plan to bring in such medications, it is advised to contact the Ministry of Food and Drug Safety in advance (Narcotic Policy Division of MFDS ☎043-719-2810).",
    },
    discount: {
      title: "discounts for people with disabilities",
      content1:
        "In Korea, there are various discounts available for people with disabilities, including on public transportation, admission to tourist attractions, and use of public parking facilities. To receive these benefits, most places require a disability welfare card or proof recognized by the Korean government. However, it is very difficult for tourists to obtain such proof.",
      content2:
        "Nevertheless, depending on local governments, tourist attractions, or public facilities, some places may provide discounts for people with disabilities even without official proof. It is recommended to inquire beforehand or at the entrance to confirm whether discounts are available.",
    },
    help: {
      title: "Emergency",
      content1:
        "In case of an emergency, you can get help by contacting emergency services. The emergency numbers for different situations are as follows: Police (112), Fire Department and Emergency Medical Services (119), Infectious Disease Control (1339). For travel-related emergency consultation, you can contact 1330, and for lost items, you can reach out to 182 for assistance.",
      content2:
        "It's a good idea to download and store this information as an image on your smartphone so that you can quickly refer to it in an emergency situation.",
    },
    airplane: {
      title: "Guidelines for Airplane Use",
      content1:
        "Wheelchair Use: When using a wheelchair in-flight, it is possible to use an in-flight wheelchair by making a reservation in advance. (When using a personal wheelchair, it must meet the in-flight size requirements (39 cm × length 69 cm × height 84 cm)). Personal wheelchairs can be transported free of charge when using the in-flight wheelchair.",
      content2:
        "Electric Wheelchair Use: Electric wheelchairs must be checked in during boarding, and usually, passengers use a wheelchair provided by the airline up to the boarding gate. Electric wheelchairs are transported free of charge, and if the battery is non-removable, it must be secured so that no current flows before transportation. If the battery is removable, it must be carried separately, and the battery should not exceed 300Wh. If the wheelchair operates on two batteries, the total of both should not exceed 300Wh. Transportation availability and procedures for electric wheelchairs may vary depending on the size and battery type, so please contact the airline's service center for detailed information.",
      content3:
        "Use of Auxiliary Breathing Equipment: Passengers using auxiliary breathing equipment must contact the airline's service center at least 48 hours before departure. For safety reasons, the in-flight power supply cannot be used. The equipment must be a dry or lithium battery type of 160Wh or less and must be carried directly. Passengers using a portable oxygen concentrator (POC) must submit a doctor's statement at the time of reservation, and it must have a test result label that meets the requirements of the U.S. Federal Aviation Administration (FAA) or the relevant country. The doctor's statement form can be downloaded from the airline's website.",
    },
  },
  tool: {
    increaseText: "Increase Text Size",
    decreaseText: "Decrease Text Size",
    resetTitle: "Reset",
    closeTitle: "Close",
    menuTitle: "Web Accessibility Tools",
    increaseTextSpacing: "Increase Text Spacing",
    decreaseTextSpacing: "Decrease Text Spacing",
    increaseLineHeight: "Increase Line Height",
    decreaseLineHeight: "Decrease Line Height",
    invertColors: "Invert Colors",
    grayHues: "Gray Hues",
    underlineLinks: "Underline Links",
    bigCursor: "Big Cursor",
    readingGuide: "Reading Guide",
    textToSpeech: "Text to Speech",
    speechToText: "Speech to Text",
    disableAnimations: "Disable Animations",
    hotkeyPrefix: "Hotkey:",
  },
};
